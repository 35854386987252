import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LogIn } from './LogIn';
import { checkLoggedIn } from '../functions/CookieHelper';

export const Home = () => {
    const [loggedIn, setLoggedIn] = useState(checkLoggedIn());
    const [user, setUser] = useState({});

    useEffect(() => {
        if (loggedIn) {
            const loadUser = async () => {
                const response = await fetch('api/identity/getuser');
                const data = await response.json();
                setUser(data);
                console.log('user', data);
            }
            loadUser();
        }
    }, [loggedIn]);

    let content = '';

    if (loggedIn) {
        content = (
            <div>
                {/*<Link to="/overview" className="btn btn-primary">Projects</Link>*/}
                {user && user.customer === 'Capital' && (
                    <div>
                        <br />
                        <Link to="/hammertrak/portal" className="btn btn-primary">Go To HammerTrak Data</Link>
                    </div>
                )
                }
            </div>
        );
    }
    else {
        content = (
            <div>
                <LogIn />
            </div>
        );
    }

    return (
        <div className="text-center">
            <h1>Welcome to DrillTrak</h1>
            {content}
        </div>
    );
}
