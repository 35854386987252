import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Navigator = () => {
    const [softwareVersion, setSoftwareVersion] = useState('loading ...');
    const [releaseDate, setReleaseDate] = useState('');
    const [releaseNotes, setReleaseNotes] = useState([]);

    useEffect(() => {
        const loadVersion = async () => {
            const response = await fetch('api/navigator/version');
            const data = await response.text();
            let parts = data.split('\n');
            if (parts.length > 1) {
                setSoftwareVersion(parts[0]);
                setReleaseDate(parts[1]);
                var releaseLines = parts.slice(2);
                //for (var i = 0; i < releaseLines.length; i++) {
                //    releaseLines[i] = releaseLines[i].replace('\t', < pre >&#9;</pre >);
                //}
                setReleaseNotes(releaseLines);
            }
        }
        loadVersion();
    }, []);

    return (
        <div>
            <div className="container">
                <div className="row">
                    <h1>Navigator</h1>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="card">
                            <div className="card-header">
                                <h3>Desktop application</h3>
                            </div>
                            <div className="card-body">
                                <h4>Version: {softwareVersion}</h4>
                                <h4>Released: {releaseDate}</h4>
                                <h4>Release notes:</h4>
                                <ul className="no-bullets">
                                    {releaseNotes.map((note, index) => (
                                        <li key={index}>{note.replace('\t', ' - ')}</li>
                                    ))}
                                </ul>
                                <a href="api/navigator/download" target="_blank" rel="noopener noreferrer"><button className="btn btn-secondary">Download</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}