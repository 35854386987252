import React, { useState, useEffect } from 'react';
import { FaTrash, FaShare } from 'react-icons/fa';

export const TableTab = ({ drillhole }) => {
    const [loading, setLoading] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState('');
    const [survey, setSurvey] = useState({});
    const [rawData, setRawData] = useState({});


    const handleSurveyDelete = () => {
        if (window.confirm('Are you sure you want to delete this survey?')) {
            alert('delete survey ' + selectedSurvey + ', coming soon');
        }
    }

    const handleSurveyShare = () => {
        alert('share survey ' + selectedSurvey + ', coming soon');
    }

    const handleSurveySelect = (event) => {
        if (event.target.value === '') {
            return;
        }
        setSelectedSurvey(event.target.value);
        const surveyReference = drillhole.surveys.find(survey => survey.fileName === event.target.value);
        loadSurvey(surveyReference);
    }

    const loadSurvey = async (surveyReference) => {
        if (!surveyReference) {
            return;
        }
        setLoading(true);
        const response = await fetch('api/surveys/' + surveyReference.fileId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        setSurvey(data);
        setLoading(false);
        setRawData({});
    }

    const printRawData = (data, source) => {
        data.source = source;
        setRawData(data);
        }


    useEffect(() => {
        setLoading(false);
        setSelectedSurvey('');
        setSurvey({});
    }, [drillhole]);

    const renderFailiures = (survey) => {
        if (survey.FailedShots && survey.FailedShots.length > 0) {
            return (
                <div>
                    <h3>Failed Shots</h3>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Inclination</th>
                                <th>Azimuth</th>
                                <th>Toolface</th>
                                <th>GTotal</th>
                                <th>WTotal</th>
                                <th>Reason</th>
                                <th>TimeStamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {survey.FailedShots.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row.OrientationSet.Inclination.toFixed(2)}</td>
                                        <td>{row.OrientationSet.Azimuth.toFixed(2)}</td>
                                        <td>{row.OrientationSet.HighSide.toFixed(2)}</td>
                                        <td>{row.OrientationSet.GTotal.toFixed(2)}</td>
                                        <td>{row.OrientationSet.WTotal.toFixed(2)}</td>
                                        <td>{row.Reason}</td>
                                        <td>{row.TimeStamp}</td>
                                        {(row.OrientationSet.SensorSets && row.OrientationSet.SensorSets.length > 0) &&
                                            <td><button className="btn btn-sm btn-primary"
                                                onClick={() => printRawData(row.OrientationSet, 'Failed shot')}>
                                                Raw</button></td>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        else {
            return <p>No failed shot data available</p>
        }
    }

    const renderPreRunGyrocompasses = (survey) => {
        if (survey.PreRunGyrocompasses && survey.PreRunGyrocompasses.length > 0) {
            return (
                <div>
                    <h3>Pre-run Gyrocompasses</h3>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Inclination</th>
                                <th>Azimuth</th>
                                <th>Toolface</th>
                                <th>GTotal</th>
                                <th>WTotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {survey.PreRunGyrocompasses.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.Inclination.toFixed(2)}</td>
                                        <td>{row.Azimuth.toFixed(2)}</td>
                                        <td>{row.HighSide.toFixed(2)}</td>
                                        <td>{row.GTotal.toFixed(2)}</td>
                                        <td>{row.WTotal.toFixed(2)}</td>
                                        {(row.SensorSets && row.SensorSets.length > 0) &&
                                            <td><button className="btn btn-sm btn-primary"
                                                onClick={() => printRawData(row, 'Pre-run gyrocompass')}>
                                                Raw</button></td>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        else {
            return <p>No pre-run gyrocompass data available</p>
        }
    }


    const renderRawData = (data) => {
        return (
            <div>
                <h3>Raw Data View</h3>
                <p>Source: {data.source}
                <br />Inc: {data.Inclination.toFixed(2)} Az: {data.Azimuth.toFixed(2)}</p>
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Kx</th>
                            <th>Kz</th>
                            <th>Gx</th>
                            <th>Gy</th>
                            <th>Gz</th>
                            <th>Encoder</th>
                            <th>TimeStamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.SensorSets.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td>{row.Kx}</td>
                                    <td>{row.Kz}</td>
                                    <td>{row.Gx}</td>
                                    <td>{row.Gy}</td>
                                    <td>{row.Gz}</td>
                                    <td>{row.Encoder.toFixed(1)}</td>
                                    <td>{row.TimeStamp}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }


    const renderSurveyTable = (survey) => {
        if (survey.surveyLines) {
            return survey.surveyLines && (
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Depth</th>
                            <th>Inclination</th>
                            <th>Azimuth</th>
                            <th>North</th>
                            <th>East</th>
                            <th>Tvd</th>
                        </tr>
                    </thead>
                    <tbody>
                        {survey.surveyLines.map((row, index) => {
                            return <tr key={index}>
                                <td>{row.depth.toFixed(2)}</td>
                                <td>{row.inclination.toFixed(2)}</td>
                                <td>{row.azimuth.toFixed(2)}</td>
                                <td>{row.northing.toFixed(2)}</td>
                                <td>{row.easting.toFixed(2)}</td>
                                <td>{row.tvd.toFixed(2)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            )
        }
        else if (survey.SavedShots) {
            // This is the new format
            return survey.SavedShots && (
                <div className="overflow-auto">
                    <h3>Saved Shots</h3>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Joint</th>
                                <th>Depth</th>
                                <th>Inclination</th>
                                <th>Azimuth</th>
                                <th>-L/R</th>
                                <th>Away</th>
                                <th>Elevation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <tr><td colSpan="7">Loading...</td></tr> : survey.SavedShots.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td>{row.Depth.toFixed(2)}</td>
                                        <td>{row.OrientationSet.Inclination.toFixed(2)}</td>
                                        <td>{row.OrientationSet.Azimuth.toFixed(2)}</td>
                                        <td>{row.PositionSet.LeftRight.toFixed(2)}</td>
                                        <td>{row.PositionSet.Away.toFixed(2)}</td>
                                        <td>{row.PositionSet.Elevation.toFixed(2)}</td>
                                        {(row.OrientationSet.SensorSets && row.OrientationSet.SensorSets.length > 0) &&
                                            <td><button className="btn btn-sm btn-primary"
                                                onClick={() => printRawData(row.OrientationSet, 'Saved shot')}>
                                                Raw</button></td>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {renderPreRunGyrocompasses(survey) }
                    {renderFailiures(survey)}
                    {rawData && rawData.SensorSets && renderRawData(rawData)}
                </div>
            )
        }
        else {
            return <p>No data available</p>
        }
    }


    return (
        <div className="container" style={{ marginTop: 10 }}>
            <div className="row">
                <div className="col-lg-12">
                    <div className="d-flex">
                        <h3>Data Tables</h3>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="d-flex">
                        <div >
                            <select className="form-select form-select-sm" value={selectedSurvey} onChange={handleSurveySelect} style={{ minWidth: 300 }}>
                                <option value=''>Select a Survey</option>
                                {drillhole.surveys && drillhole.surveys.sort((a, b) => a.fileName.localeCompare(b.fileName)).map((survey, index) => {
                                    return <option key={index} value={survey.fileName}> {survey.fileName} </option>
                                })}
                            </select>
                        </div>
                        {selectedSurvey !== '' && (
                            <div className="ms-auto">
                                <button className="btn btn-primary" style={{ marginRight: 10 }} onClick={() => handleSurveyShare()}><FaShare /> Share</button>
                                <button className="btn btn-danger" onClick={() => handleSurveyDelete()}><FaTrash /> Delete</button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="overflow-auto">
                        {loading ? <p><em>Loading...</em></p> : renderSurveyTable(survey)}
                    </div>
                </div>
            </div>
        </div>
    );
}