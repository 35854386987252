import React, { useState, useEffect } from 'react';
import { Line3DChart } from './Line3DChart';

export const ChartsTab = ({ drillhole }) => {
    const [loading, setLoading] = useState(true);
    const [surveyCollection, setSurveyCollection] = useState([]);

    useEffect(() => {
        setSurveyCollection([]);
        setLoading(true);
        drillhole.surveys.forEach(surveyReference => {
            fetch('api/surveys/' + surveyReference.fileId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.Created) {
                        var survey = { name: data.Name, surveyLines: data.SavedShots.map((shot) => { return { easting: shot.PositionSet.Easting, northing: shot.PositionSet.Northing, tvd: shot.PositionSet.Elevation } }) };
                        setSurveyCollection(surveyCollection => [...surveyCollection, survey]);
                    }
                    else {
                        setSurveyCollection(surveyCollection => [...surveyCollection, data]);
                    }
                });
        });

        setLoading(false);
    }, [drillhole])


    return (
        <div className="container" style={{ marginTop: 10 }}>
            <div className="row">
                <div className="col-lg-12">
                    <h3>Charts</h3>
                </div>
                <div className="col-lg-12 d-flex">
                    <h5 style={{ marginRight: 10 }}>Chart Type</h5>
                    <select className='form-select form-select-sm' style={{ maxWidth: 300 }}>
                        <option value='3D'>3D</option>
                    </select>
                </div>
                <div className="col-lg-12">
                    {surveyCollection.length > 0 && < Line3DChart title='' xAxisName='East' yAxisName='North' zAxisName='TVD'
                        seriesCollection={surveyCollection.map((survey) => { return { name: survey.name, points: survey.surveyLines.map((line) => [line.easting, line.northing, -line.tvd]) } })} />}
                </div>
            </div>
        </div>
    );
}