import React, { useState, useEffect } from 'react';

export const HTRigList = ({ handleRigExplore }) => {
    const [rigs, setRigs] = useState([]);
    const [displayedRigs, setDisplayedRigs] = useState([rigs]);
    const [selectedRig, setSelectedRig] = useState('');

    useEffect(() => {
        const loadRigs = async () => {
            const response = await fetch('api/hammertrak/rigs');
            const data = await response.json();
            setRigs(data);
            setDisplayedRigs(data);
            if (data.length > 0) {
                var rig = data[data.length - 1];
                setSelectedRig(rig);
                handleRigExplore(rig);
            }
            console.log('rigs', data);
        }
        loadRigs();
    }, []);

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filteredRigs = rigs.filter(rig => rig.toLowerCase().includes(searchTerm));
        setDisplayedRigs(filteredRigs);
    }

    const handleSelectionChanged = (event) => {
        if (event.target.value === '') {
            return;
        }
        setSelectedRig(event.target.value);
        handleRigExplore(event.target.value);
    }

    if (rigs == null || rigs.length === 0) {
        return <p>Loading ...</p>;
    }

    return (
        <div>
            <h3>Rigs</h3>
            {/*<input type="text" className="form-control" placeholder="Search" onChange={handleSearch}/>*/}
            <select className="form-select form-select-sm" onChange={handleSelectionChanged} value={selectedRig}>
                <option value=''>Select a Rig</option>
                {displayedRigs.map(rig =>
                    <option key={rig} value={rig}>{rig}</option>
                )}
            </select>
        </div>
    );
}