import React, { useState, useEffect } from 'react';

export const SurveyPreviewTable = ({ surveyReference }) => {
    const [loading, setLoading] = useState(true);
    const [survey, setSurvey] = useState({});

    useEffect(() => {
        const loadSurvey = async () => {
            const response = await fetch('api/surveys/' + surveyReference.fileId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setSurvey(data);
        }
        if (surveyReference.key) {

            loadSurvey();
            setLoading(false);
        }
    }, [surveyReference]);

    return (
        <div>
            <h2>Survey Preview</h2>
            <h4>{survey.Name}</h4>
            <p>{survey.Description}</p>
            {survey.SavedShots && <h5>Number of saved shots: {survey.SavedShots.length}</h5>}
            {survey.Created && <h5>Survey started: {new Date(survey.Created).toLocaleDateString()} {new Date(survey.Created).toLocaleTimeString()}</h5>}
            {survey.LastModified && <h5>Survey finished: {new Date(survey.LastModified).toLocaleDateString()} {new Date(survey.LastModified).toLocaleTimeString()}</h5>}
            {survey.surveyTool && < h5 > Survey tool: {survey.surveyTool}</h5>}
            {survey.SavedShots && (
                <div className="overflow-auto" style={{ maxHeight: 500 }}>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Depth</th>
                                <th>Inclination</th>
                                <th>Azimuth</th>
                                <th>-L/R</th>
                                <th>Away</th>
                                <th>Elevation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <tr><td colSpan="7">Loading...</td></tr> : survey.SavedShots.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row.Depth.toFixed(2)}</td>
                                        <td>{row.OrientationSet.Inclination.toFixed(2)}</td>
                                        <td>{row.OrientationSet.Azimuth.toFixed(2)}</td>
                                        <td>{row.PositionSet.LeftRight.toFixed(2)}</td>
                                        <td>{row.PositionSet.Away.toFixed(2)}</td>
                                        <td>{row.PositionSet.Elevation.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}


            {survey.surveyLines && (
                <div className="overflow-auto" style={{ maxHeight: 500 }}>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Depth</th>
                                <th>Inclination</th>
                                <th>Azimuth</th>
                                <th>North</th>
                                <th>East</th>
                                <th>Tvd</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <tr><td colSpan="7">Loading...</td></tr> : survey.surveyLines.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row.depth.toFixed(2)}</td>
                                        <td>{row.inclination.toFixed(2)}</td>
                                        <td>{row.azimuth.toFixed(2)}</td>
                                        <td>{row.northing.toFixed(2)}</td>
                                        <td>{row.easting.toFixed(2)}</td>
                                        <td>{row.tvd.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}